import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {environment} from "../../../../environments/environment";
import {ErrorStateMatcher} from "@angular/material/core";

// @ts-ignore
import Swal from 'sweetalert2/dist/sweetalert2.js';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-editar-assinatura',
  templateUrl: './editar-assinatura.component.html',
  styleUrls: ['./editar-assinatura.component.css'],
  providers: [DatePipe]
})
export class EditarAssinaturaComponent implements OnInit {




  cpf = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  formaDePagamanto = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  beneficio_id = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  valorMensal = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  repetirPeriodo = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  recorrenciaTermino = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name
  DiaGerarFatura = new FormControl('', [
    Validators.required,
    Validators.max(28)
  ]);
  // tslint:disable-next-line:variable-name
  DiaVencimento = new FormControl('', [
    Validators.required,
    Validators.max(28)
  ]);

  // tslint:disable-next-line:variable-name
  datafimpagamanto = new FormControl('', [
    Validators.required,
  ]);
  // tslint:disable-next-line:variable-name


  model: NgbDateStruct | any;
  avatar: any =  '../../assets/img/avatar.jpg';
  erroform: any =  '0';
  usuarios: any = '';
  imglogo: any = '';
  url: any;
  // tslint:disable-next-line:max-line-length
  editarConta: any = { password: '', cpf: '', formaDePagamanto: 'CARTAO', beneficio_id: 1, valorMensal: 0.00, repetirPeriodo: 'MES', recorrenciaTermino: '', DiaGerarFatura: '', DiaVencimento: '', nomecartao: '', numeroCartao: '', dataVencimentocartao: '', cvc: ''};
  cidades: any;
  erro: any = 0;
  modal = false;
  modalerro = false;
  passwordativo = false;


  // tslint:disable-next-line:variable-name
  tipobeneficios: any ;
  private person: any;
  constructor(private route: ActivatedRoute, private http: HttpClient,
              private datePipe: DatePipe, private router: Router) {
  }
  ngOnInit(): void {
    this.url = environment.url;
    // @ts-ignore
    console.log(this.teste);
    // this.editarConta.avatar =  this.url +  this.editarConta.avatar;
    // this.editarConta.nascimento = this.datePipe.transform(this.editarConta.nascimento, 'dd/MM/yyyy');
    // tslint:disable-next-line:triple-equals

    this.getbeneficios().subscribe(dadosbene => {
      console.log(dadosbene);
      this.tipobeneficios = dadosbene;
    });
    const idassinatura = localStorage.getItem('assinatura');

    this.getassinatura(idassinatura).subscribe(dadosbene => {
      this.editarConta = dadosbene;
    });




  }
  // tslint:disable-next-line:typedef
  abrirsenha() {
    this.erroform = '0';
    if (this.cpf.hasError('required')){ this.erroform = '1'; }
    if (this.formaDePagamanto.hasError('required')){ this.erroform = '1'; }
    if (this.beneficio_id.hasError('required')){ this.erroform = '1'; }
    if (this.valorMensal.hasError('required')){ this.erroform = '1'; }
    if (this.repetirPeriodo.hasError('required')){ this.erroform = '1'; }
    if (this.recorrenciaTermino.hasError('required')){ this.erroform = '1'; }
    if (this.DiaGerarFatura.hasError('required')){ this.erroform = '1'; }
    if (this.DiaVencimento.hasError('required')){ this.erroform = '1'; }






    // tslint:disable-next-line:triple-equals
    if(this.erroform == '0'){
      this.passwordativo = true;
    }

  }
  // tslint:disable-next-line:typedef
  Password(senha: any) {
    this.editarConta.password = senha.password;


    // tslint:disable-next-line:triple-equals
    if (senha.tipoa == 'cancelar'){
      this.passwordativo = false;
    }else{
      this.atualizarConta();
      this.passwordativo = false;
    }
    console.log( senha);
  }
  parseDate(dateString: string): Date | null {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  // tslint:disable-next-line:typedef
  onSelectFile( event: any )  {
    this.readThis(event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imglogo = myReader.result;
      this.editarConta.logoCartao = myReader.result;
      this.erroform = '0';
    };
    myReader.readAsDataURL(file);
    console.log(this.editarConta);

  }


  getassinatura(id:any) {

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/appdados/assinaturas/getassinatura`, {id});
  }


  // tslint:disable-next-line:typedef
  atualizarConta(){


    console.log(this.erroform);
    // tslint:disable-next-line:triple-equals
    if (this.erroform == '0') {


      this.enviarDadosConta().subscribe( dados => {

          Swal.fire({
            icon: 'success',
            title: 'Cadastrada com sucesso!',
          }).then(() => {
             this.router.navigate(['assinaturas']);
          });
        }
        ,(err) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
          });
        });




    }
  }
  // tslint:disable-next-line:typedef
  enviarDadosConta(){

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.api}/appdados/assinaturas/update`, this.editarConta);
  }
  // tslint:disable-next-line:typedef
  getbeneficios(){

    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.api}/appdados/assinaturas/getbeneficios`);
  }

  // tslint:disable-next-line:typedef
  cancelar(){

    // tslint:disable-next-line:max-line-length
    this.router.navigate(['beneficios']);
  }

  // tslint:disable-next-line:typedef
  fechar(){
    this.modalerro = false;
  }
}
