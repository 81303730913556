<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por Assinaturas...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
    <button (click)="fitrar('Ativo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'Ativo'}" class="btn btn-light2 " style="margin-left: 10px;">Ativos</button>
    <button (click)="fitrar('Finalizado')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Finalizados</button>
    <button (click)="fitrar('Cancelado')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Cancelados</button>
    <button (click)="fitrar('Pendente')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Pendentes</button>


    <button (click)="criarnovo()" type="button"  class="btn btn-light2 float-right btn_bluedark" style="">Cadastrar nova</button>
  </div>



  <table class="table "    style="border-spacing: 0 8px;">
    <thead>
    <tr style="font-size: 13px!important;" >
      <td  *ngIf="order == 'assinaturas.id' && order_tipo == 'asc' || order != 'assinaturas.id'">ID <span  (click)="ordenar('assinaturas.id')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'assinaturas.id' && order_tipo == 'desc'">ID <span  (click)="ordenar('assinaturas.id')" style="margin-left: 10px;font-size: 12px;color: #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'users.name' && order_tipo == 'asc' || order != 'name'">Nome/ razão social <span (click)="ordenar('users.name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'users.name' && order_tipo == 'desc'">Nome/ razão social <span (click)="ordenar('users.name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td  *ngIf="order == 'beneficios.name' && order_tipo == 'asc' || order != 'beneficios.name'">SERVIÇO/ BENEFÍCIO<span (click)="ordenar('beneficios.name')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'beneficios.name' && order_tipo == 'desc'">SERVIÇO/ BENEFÍCIO<span (click)="ordenar('beneficios.name')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td >VALOR<span  style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"></span></td>

      <td  *ngIf="order == 'assinaturas.formaDePagamanto' && order_tipo == 'asc' || order != 'assinaturas.formaDePagamanto'">FORMA<span (click)="ordenar('assinaturas.formaDePagamanto')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'assinaturas.formaDePagamanto' && order_tipo == 'desc'">FORMA<span (click)="ordenar('assinaturas.formaDePagamanto')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>


      <td  *ngIf="order == 'assinaturas.created_at' && order_tipo == 'asc' || order != 'assinaturas.created_at'" >ASSINADO EM <span (click)="ordenar('assinaturas.created_at')" style="margin-left: 10px;font-size: 12px;color: #4AD9A4; cursor: pointer;"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></td>
      <td  *ngIf="order == 'assinaturas.created_at' && order_tipo == 'desc'" >ASSINADO EM<span (click)="ordenar('assinaturas.created_at')" style="margin-left: 10px;font-size: 12px;color:  #000; cursor: pointer;"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></td>

      <td></td>
      <td></td>
    </tr>
    </thead>
    <tbody *ngIf="pagando == false" >
    <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
      <td >#{{usuario.id}}</td>
      <td>{{usuario.name}}</td>
      <td>{{usuario.beneficiosname }}</td>
      <td><span >{{usuario.valorMensal | currency:'BRL':true}}</span></td>
      <td>{{usuario.formaDePagamanto }}</td>

      <td><span >{{usuario.data }}</span></td>
      <td>
        <button *ngIf="usuario.status == 'Ativo'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #4AE3B5;"></div> <span style="margin-left: 10px; font-size: 12px;">Ativo</span></button>
        <button *ngIf="usuario.status == 'Finalizado'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #2019e5;"></div> <span style="margin-left: 10px; font-size: 12px;">Finalizado</span></button>
        <button *ngIf="usuario.status == 'Cancelado'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: red"></div> <span style="margin-left: 10px; font-size: 12px;">Cancelado</span></button>
        <button *ngIf="usuario.status == 'Pendente'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: gold;"></div> <span style="margin-left: 10px; font-size: 12px;">Pendente</span></button>
      </td>
      <td>
        <!-- Example split danger button -->
        <div class="btn-group">
          <button type="button" class="btn " style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Ações</button>
          <button type="button" class="btn  dropdown-toggle dropdown-toggle-split" style="background-color: #EEEFF7;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <a (click)="editar(usuario.id)" class="dropdown-item" >Editar</a>
            <a (click)="irFaturas(usuario.id)" class="dropdown-item" >Faturas</a>
            <a (click)="pagarmodal(usuario.id)" class="dropdown-item" >Cancelar</a>
            <!--            <a (click)="lancarmento(usuario.id)" class="dropdown-item" >Lançamento</a>-->

          </div>
        </div>
      </td>
    </tr>
    </tbody>

  </table>

  <nav aria-label="Page navigation example" class="">
    <ul class="pagination justify-content-end">
      <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
      </li>

      <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>


      <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
        <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
      </li>
    </ul>
  </nav>





  <div *ngIf="modalpagar == true" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #000; border-bottom: none!important;" >
          <h3>Cancelar Assinatura</h3>
          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: left;">
          <p style="font-size: 18px;"> Você tem certeza de que deseja cancelar essa assinatura? </p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-secondary">Fechar</button>
          <button type="button" (click)="pagar()"  class="btn btn-success">Cancelar Assinatura</button>
        </div>
      </div>
    </div>
  </div>




  <div *ngIf="pagando == true" id="exampleModalLive3" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <!--          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">-->
          <!--            <span aria-hidden="true">×</span>-->
          <!--          </button>-->
        </div>
        <div class="modal-body" style="text-align: center;">
          <div style="text-align: center; align-items: center; width: 100%; " >
            <mat-spinner  style="margin-left: 40%;  " color="accent"></mat-spinner>
            <div style="font-size: 20px; margin-top: 20px;">Processando pagamento ....<br>
              Por favor, não feche ou recarregue a página até que o pagamento seja concluído!
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modal == true" id="exampleModalLive1" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> Pagamento(s) concluído(s) com sucesso!</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="ok()"  class="btn btn-success">Ok</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalerro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

          <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <p style="font-size: 20px;"> {{texteerromodal}}</p>
        </div>
        <div class="modal-footer" style="border-top:none!important; ">
          <button type="button" (click)="cancelar()"  class="btn btn-danger">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>

