<div class="table-responsive">
  <div class="col-sm-12 ">

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text" style="background-color: #FFF; border: none!important;"><i class="fa fa-search" aria-hidden="true"></i></div>
      </div>
      <input type="text" style="border: none;"  class="form-control buscar" (keyup)="pesquisar($event)" [(ngModel)]="pesquisa.texto"  id="inlineFormInputGroupUsername" placeholder="Buscar por Fatura...">
    </div>
  </div>
  <div class="col-sm-12 " style="margin-top: 20px; margin-bottom: 20px;">

    <button (click)="fitrar('tudo')" type="button" [ngClass]="{'btn-selecinado': filtro === 'tudo'}" class="btn btn-light2">Tudo</button>
    <button (click)="fitrar('Pago')" type="button" [ngClass]="{'btn-selecinado': filtro === 'Ativo'}" class="btn btn-light2 " style="margin-left: 10px;">Pagos</button>
    <button (click)="fitrar('Recusado')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Recusados</button>
    <button (click)="fitrar('Cancelado')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Cancelados</button>
    <button (click)="fitrar('Pendente')" type="button" [ngClass]="{'btn-selecinado': filtro === 'bloqueadas'}" class="btn btn-light2" style="margin-left: 10px;">Pendentes</button>


  </div>



  <table class="table "    style="border-spacing: 0 8px;">
    <thead>
    <tr style="font-size: 13px!important;" >

      <td >ID</td>
      <td >ID FATURA</td>
      <td >DATA VENCIMENTO</td>
      <td >DATA GERAÇÂO FATURA</td>
      <td >VALOR</td>
      <td></td>

    </tr>
    </thead>
    <tbody *ngIf="pagando == false" >
    <tr *ngFor="let usuario of usuarios.data ; let i = index" class="tr_espace" style="background-color:#FFF; margin-top: 10px!important; margin-bottom: 10px;  ">
      <td >#{{usuario.id}}</td>
      <td>{{usuario.fatura_id}}</td>
      <td>{{usuario.dataVen }}</td>
      <td>{{usuario.dataGerar }}</td>

      <td><span >{{usuario.valor | currency:'BRL':true}}</span></td>
      <td>
        <button *ngIf="usuario.status == 'Pago'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #4AE3B5;"></div> <span style="margin-left: 10px; font-size: 12px;">Pago</span></button>
        <button *ngIf="usuario.status == 'Recusado'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: #2019e5;"></div> <span style="margin-left: 10px; font-size: 12px;">Recusado</span></button>
        <button *ngIf="usuario.status == 'Cancelado'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: red"></div> <span style="margin-left: 10px; font-size: 12px;">Cancelado</span></button>
        <button *ngIf="usuario.status == 'Pendente'" class="btn btn-light"><div style="width: 7px; height: 7px; border-radius: 7px;  margin-top: 8px; float: left;  background-color: gold;"></div> <span style="margin-left: 10px; font-size: 12px;">Pendente</span></button>
      </td>

    </tr>
    </tbody>

  </table>

  <nav aria-label="Page navigation example" class="">
    <ul class="pagination justify-content-end">
      <li class="page-item" [ngClass]="{'disabled':   usuarios.current_page === 1}" style="cursor: pointer;" >
        <a class="page-link" (click)="proximo(usuarios.current_page - 1)">Anterior</a>
      </li>

      <span *ngFor='let in of counter(usuarios.last_page) ;let i = index'>
      <li class="page-item " [ngClass]="{'active': i + 1 === usuarios.current_page}"  style="cursor: pointer;"  >
        <a class="page-link" (click)="proximo(i + 1)" >{{i+ 1}}</a></li>
    </span>


      <li  class="page-item" [ngClass]="{'disabled':   usuarios.current_page === usuarios.last_page}" style="cursor: pointer;">
        <a (click)="proximo(usuarios.current_page + 1)" class="page-link" >Proximo</a>
      </li>
    </ul>
  </nav>














