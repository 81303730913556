<div class="row" style="background-color: #FFF; margin: 10px!important;">


  <div class="col-md-4 collapse show d-md-flex bg-light pt-2 pl-0" id="sidebar" style="background-color: #FFF!important; padding: 30px!important; border-right: 2px solid #f8f9fc;">
    <ul class="nav flex-column flex-nowrap overflow-hidden menu_editar" style="font-size: 14px; color: #D9D9D9; width: 100%;">

      <li class="nav-item " [routerLinkActive]="['ativo_editar']" style="width: 100%;">
        <a class="nav-link text-truncate" style="color: #A7A9C0;" [routerLink]="['/assinaturas/editar']">
          <span class=" d-sm-inline" style="margin-left: 20px;">Editar Assinatura</span>
          <i class=" fa fa-angle-right" style="font-size: 20px; float: right; display: none;"></i>
        </a>
      </li>



    </ul>
  </div>



  <div class="col-md-8" style="padding: 30px;" (submit)="atualizarConta()">


    <h2 style="color: #33343E; font-weight: normal;"> Editar Assinatura</h2>

    <div class="form-group row">
      <div class="col-md-6 mb-3 mb-sm-0" >
        <label for="cpf">CPF do cliente <span style="color: red;">*</span></label>
        <input type="text" mask="000.000.000-00" class="form-control form-control-user" name="cpf" id="cpf" [formControl]="cpf" [(ngModel)]="editarConta.cpf"
               placeholder="CPF" required>
        <div *ngIf="cpf.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>

      <div class="col-md-6">
        <label for="formaDePagamanto">Forma de pagamento<span style="color: red;">*</span></label>

        <select name="sexo"  class="form-control" required [(ngModel)]="editarConta.formaDePagamanto" [formControl]="formaDePagamanto" id="formaDePagamanto">
          <option  value="CARTAO" selected>CARTÃO</option>
          <option  value="PIX">PIX</option>
          <option  value="BOLETO">BOLETO</option>
        </select>
        <div *ngIf="formaDePagamanto.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
    </div>

    <div class="form-group row">

      <div class="col-md-6">
        <label for="beneficio_id">Selecione o benefício <span style="color: red;">*</span></label>

        <select name="sexo"  class="form-control" required [(ngModel)]="editarConta.beneficio_id" [formControl]="beneficio_id" id="beneficio_id">
          <option *ngFor="let tipobeneficio of tipobeneficios ; let i = index"  value="{{tipobeneficio.id}}">{{tipobeneficio.name}}</option>
        </select>
        <div *ngIf="beneficio_id.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
      <div class="col-md-6">
        <label for="valorMensal">Valor Mensal <span style="color: red;">*</span></label>

        <input type="text" class="form-control form-control-user" currencyMask  name="valorMensal" [formControl]="valorMensal" [options]="{ prefix: '', thousands: '.', decimal: ',',align:'left' }"  id="valorMensal" [(ngModel)]="editarConta.valorMensal"
               placeholder="valor Mensal">


        <div *ngIf="valorMensal.hasError('required') && (erroform == '1' || erroform == '2') " style="color: red;">
          Campo obrigatório
        </div>
      </div>

    </div>

    <div class="form-group row">
      <!--      <div class="col-md-6">-->
      <!--        <label for="periodicidade">Valor Mensal <span style="color: red;">*</span></label>-->

      <!--        <select name="sexo"  required  class="form-control" [(ngModel)]="editarConta.periodicidade" [formControl]="periodicidade" id="periodicidade">-->
      <!--          <option selected value="Mensal">Mensal</option>-->
      <!--          <option value="Anual">Anual</option>-->
      <!--        </select>-->
      <!--        <div *ngIf="periodicidade.hasError('required') && (erroform == '1' || erroform == '2') " style="color: red;">-->
      <!--          Campo obrigatório-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col-md-6">
        <label for="repetirPeriodo">Repetir <span style="color: red;">*</span></label>
        <select name="sexo" required class="form-control" [(ngModel)]="editarConta.repetirPeriodo" [formControl]="repetirPeriodo" id="repetirPeriodo">
          <option selected value="MES">Mensalmente</option>
          <option value="ANO">Anualmente</option>
          <option value="SEMANA">Semanalmente</option>
          <option value="DIA">Diariamente</option>
        </select>
        <div *ngIf="repetirPeriodo.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
      </div>
      <div class="col-md-6">
        <label for="recorrenciaTermino">Termino na recorrência <span style="color: red;">*</span></label>

        <input type="date" class="form-control form-control-user"   name="recorrenciaTermino"   id="recorrenciaTermino" [(ngModel)]="editarConta.recorrenciaTermino" [formControl]="recorrenciaTermino"
               placeholder="Valor">
        <div *ngIf="recorrenciaTermino.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>

      </div>
    </div>


    <div class="form-group row">


      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="DiaGerarFatura">Dia da geração da fatura  <span style="color: red;">*</span> </label>
        <input type="number" required class="form-control form-control-user" [formControl]="DiaGerarFatura"  [(ngModel)]="editarConta.DiaGerarFatura" name="DiaGerarFatura" id="DiaGerarFatura"
        >
        <div *ngIf="DiaGerarFatura.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
        <div *ngIf="DiaGerarFatura.hasError('max') " style="color: red; margin-top: 5px; font-size: 10px;">
          Só é possível realizar a geracão  da fatura até o dia 28.
        </div>
      </div>

      <div class="col-md-6 mb-3 mb-sm-0">
        <label for="DiaVencimento">Dia da Vencimento da fatura  <span style="color: red;">*</span> </label>
        <input type="number" required class="form-control form-control-user" [formControl]="DiaVencimento"  [(ngModel)]="editarConta.DiaVencimento" name="DiaGerarFatura" id="DiaVencimento"
        >
        <div *ngIf="DiaVencimento.hasError('required') && (erroform == '1' || erroform == '2')" style="color: red;">
          Campo obrigatório
        </div>
        <div *ngIf="DiaVencimento.hasError('max') " style="color: red; margin-top: 5px; font-size: 10px;">
          Só é possível realizar o vencimento   da fatura até o dia 28.
        </div>
      </div>

    </div>
    <div *ngIf="editarConta.formaDePagamanto == 'CARTAO'">
      <h2 style="color: #33343E; font-weight: normal;"> Dados do Cartão</h2>

      <div class="form-group row">
        <div class="col-md-6 mb-3 mb-sm-0" >
          <label for="cpf">Nome Escrito no cartão </label>
          <input type="text"  class="form-control form-control-user" name="cpf" id="nomecartao" [(ngModel)]="editarConta.nomecartao"
                 placeholder="" >

        </div>

        <div class="col-md-6">
          <label for="numeroCartao">Número do cartão</label>

          <input type="text" mask="0000 0000 0000 0000" class="form-control form-control-user" name="numeroCartao" id="numeroCartao"  [(ngModel)]="editarConta.numeroCartao"
                 placeholder="" >


        </div>
      </div>


      <div class="form-group row">
        <div class="col-md-6 mb-3 mb-sm-0" >
          <label for="dataVencimentocartao">Data de Vencimento do cartão</label>
          <input type="text" mask="00/0000"  class="form-control form-control-user" name="cpf" id="dataVencimentocartao" [(ngModel)]="editarConta.dataVencimentocartao"
                 placeholder="00/0000" >

        </div>

        <div class="col-md-6">
          <label for="cvc">CVC</label>

          <input type="text" mask="000" class="form-control form-control-user" name="cvc" id="cvc"  [(ngModel)]="editarConta.cvc"
                 placeholder="123" >


        </div>
      </div>
    </div>






    <a  class="btn btn-primary" (click)="abrirsenha()"   style="width: 200px; color: #FFF; height: 40px; background-color: #306C73;" >
      Editar
    </a>
    <a class="btn "  (click)="cancelar()" style="width: 200px; color: #FFF; height: 40px; color: red;">Cancelar</a>


  </div>


</div>


<div *ngIf="modal == true" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="cancelar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> Beneficio cadastrado com sucesso!</p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="cancelar()"  class="btn btn-success">Ok</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="modalerro == true" id="exampleModalerro" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header " style="color: #FFF; border-bottom: none!important;" >

        <button type="button" class="close" (click)="fechar()"  data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;"> Erro ao cadastrar, tente novamente mais tarde!</p>
      </div>
      <div class="modal-footer" style="border-top:none!important; ">
        <button type="button" (click)="fechar()"  class="btn btn-danger">Ok</button>
      </div>
    </div>
  </div>
</div>

<app-password *ngIf="passwordativo == true" (senha)="Password($event)">

</app-password>
